exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adaptivnyj-dizajn-sajta-js": () => import("./../../../src/pages/adaptivnyj-dizajn-sajta.js" /* webpackChunkName: "component---src-pages-adaptivnyj-dizajn-sajta-js" */),
  "component---src-pages-cena-na-razrabotku-sayta-js": () => import("./../../../src/pages/cena-na-razrabotku-sayta.js" /* webpackChunkName: "component---src-pages-cena-na-razrabotku-sayta-js" */),
  "component---src-pages-contakty-js": () => import("./../../../src/pages/contakty.js" /* webpackChunkName: "component---src-pages-contakty-js" */),
  "component---src-pages-dorabotka-saytov-js": () => import("./../../../src/pages/dorabotka-saytov.js" /* webpackChunkName: "component---src-pages-dorabotka-saytov-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacionnoe-soprovozhdenie-sajta-js": () => import("./../../../src/pages/informacionnoe-soprovozhdenie-sajta.js" /* webpackChunkName: "component---src-pages-informacionnoe-soprovozhdenie-sajta-js" */),
  "component---src-pages-izmenenie-dizajna-sajta-js": () => import("./../../../src/pages/izmenenie-dizajna-sajta.js" /* webpackChunkName: "component---src-pages-izmenenie-dizajna-sajta-js" */),
  "component---src-pages-kompleksnyj-audit-sajta-js": () => import("./../../../src/pages/kompleksnyj-audit-sajta.js" /* webpackChunkName: "component---src-pages-kompleksnyj-audit-sajta-js" */),
  "component---src-pages-kurslar-js": () => import("./../../../src/pages/kurslar.js" /* webpackChunkName: "component---src-pages-kurslar-js" */),
  "component---src-pages-kursy-programmirovaniya-js": () => import("./../../../src/pages/kursy-programmirovaniya.js" /* webpackChunkName: "component---src-pages-kursy-programmirovaniya-js" */),
  "component---src-pages-lendingi-js": () => import("./../../../src/pages/lendingi.js" /* webpackChunkName: "component---src-pages-lendingi-js" */),
  "component---src-pages-nashi-proekty-js": () => import("./../../../src/pages/nashi-proekty.js" /* webpackChunkName: "component---src-pages-nashi-proekty-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-ostavit-zayavku-js": () => import("./../../../src/pages/ostavit-zayavku.js" /* webpackChunkName: "component---src-pages-ostavit-zayavku-js" */),
  "component---src-pages-razovye-raboty-po-sajtu-js": () => import("./../../../src/pages/razovye-raboty-po-sajtu.js" /* webpackChunkName: "component---src-pages-razovye-raboty-po-sajtu-js" */),
  "component---src-pages-razrabotka-internet-magazinov-js": () => import("./../../../src/pages/razrabotka-internet-magazinov.js" /* webpackChunkName: "component---src-pages-razrabotka-internet-magazinov-js" */),
  "component---src-pages-razrabotka-saytov-js": () => import("./../../../src/pages/razrabotka-saytov.js" /* webpackChunkName: "component---src-pages-razrabotka-saytov-js" */),
  "component---src-pages-redizajn-sajta-js": () => import("./../../../src/pages/redizajn-sajta.js" /* webpackChunkName: "component---src-pages-redizajn-sajta-js" */),
  "component---src-pages-seo-audit-sajta-js": () => import("./../../../src/pages/seo-audit-sajta.js" /* webpackChunkName: "component---src-pages-seo-audit-sajta-js" */),
  "component---src-pages-seo-prodvizhenie-js": () => import("./../../../src/pages/seo-prodvizhenie.js" /* webpackChunkName: "component---src-pages-seo-prodvizhenie-js" */),
  "component---src-pages-soprovozhdenie-saytov-js": () => import("./../../../src/pages/soprovozhdenie-saytov.js" /* webpackChunkName: "component---src-pages-soprovozhdenie-saytov-js" */),
  "component---src-pages-sozdanie-dizayna-saytov-js": () => import("./../../../src/pages/sozdanie-dizayna-saytov.js" /* webpackChunkName: "component---src-pages-sozdanie-dizayna-saytov-js" */),
  "component---src-pages-sozdanie-korporativnogo-sayta-js": () => import("./../../../src/pages/sozdanie-korporativnogo-sayta.js" /* webpackChunkName: "component---src-pages-sozdanie-korporativnogo-sayta-js" */),
  "component---src-pages-sozdanie-sajta-vizitki-js": () => import("./../../../src/pages/sozdanie-sajta-vizitki.js" /* webpackChunkName: "component---src-pages-sozdanie-sajta-vizitki-js" */),
  "component---src-pages-tehnicheskij-audit-sajta-js": () => import("./../../../src/pages/tehnicheskij-audit-sajta.js" /* webpackChunkName: "component---src-pages-tehnicheskij-audit-sajta-js" */),
  "component---src-pages-tehnicheskoe-soprovozhdenie-sajta-js": () => import("./../../../src/pages/tehnicheskoe-soprovozhdenie-sajta.js" /* webpackChunkName: "component---src-pages-tehnicheskoe-soprovozhdenie-sajta-js" */),
  "component---src-pages-verstka-sajta-js": () => import("./../../../src/pages/verstka-sajta.js" /* webpackChunkName: "component---src-pages-verstka-sajta-js" */),
  "component---src-pages-yuzabiliti-audit-sajta-js": () => import("./../../../src/pages/yuzabiliti-audit-sajta.js" /* webpackChunkName: "component---src-pages-yuzabiliti-audit-sajta-js" */)
}

