import React from "react";
import { OrderProvider } from "./src/components/OrderContext";
import "./src/styles/global.css";

export function wrapRootElement({ element }) {
  return <OrderProvider>{element}</OrderProvider>;
}

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Это приложение было обновлено. ` +
      `Перезагрузить для отображения последней версии?`
  );
  if (answer === true) {
    window.location.reload();
  }
};
